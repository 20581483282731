<template>
	<div class="box">
		<CustomTable
            id_table="semence_cuve_shipped"
            ref="table"
            :items="cuves"
            :busy.sync="table_busy"
            primaryKey="semencelocalisation_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
        />

		<b-modal ref="modal-courrier" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.congelation.courrier_cuve_retour") }}
				</template>
			</template>

			<div v-if="cuve_courrier" class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="$t('horse.search_doc')"
							:deselectLabel="$t('global.press_enter_to_remove')"
							:selectedLabel="$t('global.selected_label')"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						/>
					</div>
				</div>
				<div class="col-12 text-center">
					<b-button class="mt-1 rounded-pill" variant="primary" @click="print">{{ $t("global.print") }} <font-awesome-icon v-if="processing_courrier" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from "@/mixins/Navigation.js"
	import Model from "@/mixins/Model.js"

	export default {
		name: 'CongelationCuveShipped',
		mixins: [Congelation, Navigation, Model],
		data () {
			return {
				cuves: [],
				table_busy: false,
				config_table_hrefs: {
					'destinataire.tiers_rs': {
						routeUniqueName: 'destinataireFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					},
				},
				events_tab: {
					'TableAction::goToConfirmCuveRetour': this.goToConfirmCuveRetour,
					'TableAction::goToSendCuveRetour': (cuve) => {
						this.openModalCourrier(cuve)
					},
				},
				cuve_courrier: null,
				processing_courrier: false,
				templates: [],
				template: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.cuves = await this.getCuvesShipped()
				this.table_busy = false
			},

			async goToConfirmCuveRetour(cuves) {
				const cuves_ids = cuves.map(loc => loc.semencelocalisation_id).join(',')
				await this.confirmCuveRetour(cuves_ids)
				EventBus.$emit('TableAction::stopSpin')
				this.init_component()
				this.$refs.table.unselectAll()
			},

			async openModalCourrier(cuve) {
				this.cuve_courrier = cuve
				this.templates = await this.loadAllModel(0, ['retour_cuve'])
				this.$refs['modal-courrier'].show()
			},

			async print() {
				if(!this.template) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}

				this.processing_courrier = true
				await this.sendRetourCuve(this.cuve_courrier.semencelocalisation_id, this.template.model_id)
				this.processing_courrier = false
				this.$refs['modal-courrier'].hide()
				this.template = null
				this.cuve_courrier = null
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>